<template>
  <div
    class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50"
    @click="closeModal($event)"
    v-if="store.state.modal"
  >
    <div class="flex flex-col max-w-screen-sm gap-4 p-8 bg-white rounded-lg">
      <h1 class="text-4xl font-bold font-heading">Gradient Designer</h1>

      <div class="flex flex-col gap-2">
        <p>
          No matter if you are using TailwindCSS or just plain CSS, you can make
          beautiful gradients with this tool.
        </p>
        <p>Donate - Visit Github.</p>
      </div>

      <div class="flex flex-col gap-2">
        <h2 class="text-2xl font-bold font-heading">Libraries used</h2>
        <ul>
          <li>
            <a
              href="https://tailwindcss.com/"
              target="_blank"
              class="block py-0.5 text-lightBlue-600 hover:underline"
              >Tailwindcss</a
            >
          </li>
          <li>
            <a
              href="https://remixicon.com/"
              target="_blank"
              class="text-lightBlue-600 py-0.5 block hover:underline"
              >RemixIcon</a
            >
          </li>
          <li>
            <a
              href="https://vuejs.org/"
              target="_blank"
              class="text-lightBlue-600 py-0.5 block hover:underline"
              >VueJS</a
            >
          </li>
        </ul>
      </div>

      <div class="flex items-center justify-between pt-4 border-t">
        <div>
          <a
            href="https://www.paypal.me/devoneraab"
            class="inline-block p-2 rounded"
            target="_blank"
            style="vertical-align: top;"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="124" height="33">
              <path
                d="M46.2 6.75h-6.84a.95.95 0 0 0-.939.802L35.665 25.09a.57.57 0 0 0 .564.658h3.265a.95.95 0 0 0 .939-.803l.746-4.73a.95.95 0 0 1 .938-.803h2.165c4.505 0 7.105-2.18 7.784-6.5.306-1.9.013-3.375-.872-4.415C50.224 7.353 48.5 6.75 46.2 6.75zm.8 6.405c-.374 2.454-2.25 2.454-4.062 2.454h-1.032l.724-4.583a.57.57 0 0 1 .563-.481h.473c1.235 0 2.4 0 3.002.704.36.42.47 1.044.332 1.906zm19.654-.08H63.38a.57.57 0 0 0-.563.481l-.145.916-.23-.332c-.7-1.03-2.3-1.373-3.868-1.373-3.62 0-6.7 2.74-7.312 6.586-.313 1.918.132 3.752 1.22 5.03.998 1.176 2.426 1.666 4.125 1.666 2.916 0 4.533-1.875 4.533-1.875l-.146.9a.57.57 0 0 0 .562.66h2.95a.95.95 0 0 0 .939-.803l1.77-11.2a.57.57 0 0 0-.561-.658zM62.1 19.45c-.316 1.87-1.8 3.127-3.695 3.127-.95 0-1.7-.305-2.2-.883s-.668-1.4-.514-2.3c.295-1.855 1.805-3.152 3.67-3.152.93 0 1.686.3 2.184.892.5.6.697 1.4.554 2.317zm22.006-6.375h-3.3a.95.95 0 0 0-.787.417l-4.54 6.686-1.924-6.425c-.12-.402-.492-.678-.912-.678H69.4a.57.57 0 0 0-.541.754l3.625 10.638-3.408 4.8a.57.57 0 0 0 .465.9h3.287a.95.95 0 0 0 .781-.408l10.946-15.8a.57.57 0 0 0-.468-.895z"
                fill="#253b80"
              />
              <path
                d="M94.992 6.75h-6.84a.95.95 0 0 0-.938.802L84.448 25.09a.57.57 0 0 0 .562.658h3.5c.326 0 .605-.238.656-.562l.785-4.97a.95.95 0 0 1 .938-.803h2.164c4.506 0 7.105-2.18 7.785-6.5.307-1.9.012-3.375-.873-4.415-.97-1.142-2.694-1.746-4.983-1.746zm.8 6.405c-.373 2.454-2.248 2.454-4.062 2.454H90.7l.725-4.583a.57.57 0 0 1 .562-.481h.473c1.234 0 2.4 0 3.002.704.36.42.468 1.044.33 1.906zm19.652-.08h-3.273a.57.57 0 0 0-.562.48l-.145.916-.23-.332c-.7-1.03-2.3-1.373-3.867-1.373-3.62 0-6.7 2.74-7.3 6.586-.312 1.918.13 3.752 1.22 5.03 1 1.176 2.426 1.666 4.125 1.666 2.916 0 4.533-1.875 4.533-1.875l-.146.9a.57.57 0 0 0 .564.66h2.95a.95.95 0 0 0 .938-.803l1.77-11.2a.57.57 0 0 0-.565-.658zm-4.565 6.374c-.314 1.87-1.8 3.127-3.695 3.127-.95 0-1.7-.305-2.2-.883s-.666-1.4-.514-2.3c.297-1.855 1.805-3.152 3.67-3.152.93 0 1.686.3 2.184.892.5.6.7 1.4.554 2.317zm8.426-12.22l-2.807 17.858a.57.57 0 0 0 .562.658h2.822a.95.95 0 0 0 .939-.803l2.768-17.536a.57.57 0 0 0-.562-.659h-3.16a.57.57 0 0 0-.562.482z"
                fill="#179bd7"
              />
              <path
                fill="#253b80"
                d="M7.266 29.154l.523-3.322-1.165-.027H1.06L4.927 1.292c.012-.074.05-.143.108-.192s.13-.076.206-.076h9.38c3.114 0 5.263.648 6.385 1.927.526.6.86 1.227 1.023 1.917.17.724.173 1.59.007 2.644l-.012.077v.676l.526.298c.443.235.795.504 1.065.812.45.513.74 1.165.864 1.938.127.795.085 1.74-.123 2.812-.24 1.232-.628 2.305-1.152 3.183-.482.81-1.096 1.48-1.825 2-.696.494-1.523.87-2.458 1.11-.906.236-1.94.355-3.072.355h-.73c-.522 0-1.03.188-1.427.525a2.21 2.21 0 0 0-.744 1.328l-.055.3-.924 5.855-.042.215c-.01.068-.03.102-.058.125s-.06.035-.096.035H7.266z"
              />
              <path
                fill="#179bd7"
                d="M23.048 7.667h0l-.096.55c-1.237 6.35-5.47 8.545-10.874 8.545H9.326c-.66 0-1.218.48-1.32 1.132h0l-1.808 11.47c-.067.428.263.814.695.814h4.88c.578 0 1.07-.42 1.16-1l.048-.248.92-5.832.06-.32c.1-.572.582-.992 1.16-.992h.73c4.73 0 8.43-1.92 9.513-7.476.452-2.32.218-4.26-.978-5.622-.362-.41-.81-.752-1.336-1.03z"
              />
              <path
                fill="#222d65"
                d="M21.754 7.15L21.17 7l-.62-.117c-.742-.12-1.555-.177-2.426-.177h-7.352c-.18 0-.353.04-.507.115a1.17 1.17 0 0 0-.652.877l-1.61 10.195c.103-.652.66-1.132 1.32-1.132h2.752c5.405 0 9.637-2.195 10.874-8.545.037-.188.068-.37.096-.55-.313-.166-.652-.308-1.017-.43-.1-.03-.183-.06-.277-.087z"
              />
              <path
                fill="#253b80"
                d="M9.614 7.7a1.17 1.17 0 0 1 1.159-.991h7.352c.87 0 1.684.057 2.426.177l.62.117.584.15.278.086c.365.12.704.264 1.017.43.368-2.347-.003-3.945-1.272-5.392C20.378.682 17.853 0 14.622 0h-9.38a1.34 1.34 0 0 0-1.325 1.133L.01 25.898c-.077.5.3.932.795.932h5.79L9.614 7.7z"
              />
            </svg>
          </a>
          <div class="text-sm text-gray-500">Donate to support my work</div>
        </div>
        <div>
          <a
            href="https://github.com/jenstornell/gradient-designer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="w-16 h-16"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 2C6.475 2 2 6.475 2 12a9.994 9.994 0 0 0 6.838 9.488c.5.087.687-.213.687-.476 0-.237-.013-1.024-.013-1.862-2.512.463-3.162-.612-3.362-1.175-.113-.288-.6-1.175-1.025-1.413-.35-.187-.85-.65-.013-.662.788-.013 1.35.725 1.538 1.025.9 1.512 2.338 1.087 2.912.825.088-.65.35-1.087.638-1.337-2.225-.25-4.55-1.113-4.55-4.938 0-1.088.387-1.987 1.025-2.688-.1-.25-.45-1.275.1-2.65 0 0 .837-.262 2.75 1.026a9.28 9.28 0 0 1 2.5-.338c.85 0 1.7.112 2.5.337 1.912-1.3 2.75-1.024 2.75-1.024.55 1.375.2 2.4.1 2.65.637.7 1.025 1.587 1.025 2.687 0 3.838-2.337 4.688-4.562 4.938.362.312.675.912.675 1.85 0 1.337-.013 2.412-.013 2.75 0 .262.188.574.688.474A10.016 10.016 0 0 0 22 12c0-5.525-4.475-10-10-10z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  setup() {
    const store = inject("global");

    function closeModal(e) {
      if (e.currentTarget != e.target) return;

      store.state.modal = false;
    }

    return { store, closeModal };
  },
};
</script>
